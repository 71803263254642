import React, { Component, Fragment, cloneElement } from "react"
import { Link } from "gatsby"
import { RootWrapper, GlobalStyle } from "../Universal/style"
import LogoImage from "../../resources/images/logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin, faGithub, faReact, faNodeJs, faPhp, faJs } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import graphQLIcon from "../../resources/images/graphql-icon.png"

class Layout extends Component {
  constructor() {
    super()
    this.state = {
      tag: "",
    }
  }

  handleTagFilter = theTag => {
    this.setState({ tag: theTag })

    // remove active tags for all first.
    const allTags = document.getElementsByClassName("the-tag")
    for (let i = 0; i < allTags.length; i++) {
      allTags[i].classList.remove("active-tag")
    }
    // add active tag for current select
    const selected = document.getElementsByClassName(`tag-${theTag}`)[0]
    selected.classList.add("active-tag")
  }

  render() {
    const { data, type } = this.props
    const { tag } = this.state

    let storeTags = {}
    if (data) {
      data.forEach(portfolio => {
        portfolio.node.frontmatter.tags.forEach(tag => {
          storeTags[tag] = 0
        })
      })

      var theportfolios = data.slice()

      if (tag !== "") {
        theportfolios = theportfolios.filter(portfolio => portfolio.node.frontmatter.tags.includes(tag))
      }
    }

    const showPortfolioLink = type === "about" || type === "single"
    const showAboutLink = type === "home" || type === "single"

    return (
      <Fragment>
        <GlobalStyle />
        <RootWrapper home={type === "home"}>
          <aside>
            <div id="top">
              <div id="top-section">
                <Link id="logo-section" to={"/"}>
                  <img src={LogoImage} alt="HN Builds Logo" />
                </Link>
                <ul>
                  {showAboutLink && (
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                  )}
                  {showPortfolioLink && (
                    <li>
                      <Link to="/">Portfolio</Link>
                    </li>
                  )}
                </ul>
              </div>

              {/* Only display when not in home page -> need to create space as there are no filtered links */}
              {showPortfolioLink && <div className="blank-space" style={{ height: "250px" }}></div>}

              {data && (
                <Fragment>
                  <h3>Project Works</h3>
                  <ul id="tag-links-section">
                    <li onClick={() => this.handleTagFilter("")} className={`the-tag tag-`}>
                      All
                    </li>
                    {Object.keys(storeTags).map((tag, tagIndex) => (
                      <li key={tagIndex} onClick={() => this.handleTagFilter(tag)} className={`the-tag tag-${tag}`}>
                        <div className="text">{tag}</div>
                        <div className="icon">
                          {tagIndex === 0 ? (
                            <FontAwesomeIcon icon={faReact} />
                          ) : tagIndex === 1 ? (
                            <img src={graphQLIcon} alt="graphql icon" />
                          ) : tagIndex === 2 ? (
                            <FontAwesomeIcon icon={faNodeJs} />
                          ) : tagIndex === 3 ? (
                            <FontAwesomeIcon icon={faPhp} />
                          ) : (
                            <FontAwesomeIcon icon={faJs} />
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </Fragment>
              )}
            </div>
            <div id="bottom">
              <div id="social-section">
                <a href="https://www.linkedin.com/in/henry-nguyen-b5217956/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
                <a href="mailto:hn%347%333@gma%69l%2Ecom">
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
                <a href="https://github.com/hn4733" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faGithub} />
                </a>
              </div>
              <div className="copyright-section">{new Date().getFullYear()} &copy; HN Builds</div>
            </div>
          </aside>

          {cloneElement(this.props.children, { theportfolios })}

          <footer>
            <div className="copyright-section">{new Date().getFullYear()} &copy; HN Builds</div>
          </footer>
        </RootWrapper>
      </Fragment>
    )
  }
}

export default Layout
