import styled, { createGlobalStyle, keyframes } from "styled-components"
import masterBack from "../../resources/images/cover-back.png"

export const GlobalStyle = createGlobalStyle`
    @font-face {
      font-family: 'neucha';
      src: url("/fonts/neucha.ttf") format('truetype');
    }
    @font-face {
      font-family: 'fjalla';
      src: url("/fonts/fjalla-one-regular.ttf") format('truetype');
    }
    
    body {
      margin: 0;
    }
    h1, h2, h3, h4, h5, h6 {
      font-family: 'fjalla';
      text-transform: uppercase;
      display: inline-block;
    }
    h1 {
      font-size: 45px;
    }
    h3 {
      font-size: 30px; 
    }
    h4 {
      margin-top: 20px;
      margin-bottom: 0;
      font-size: 25px; 
    }
    p {
      font-family: 'neucha', 'open sans';
      font-size: 22px;
    }
    a {
      text-decoration: none;
      font-size: 24px;
      font-weight: normal;
      color: #106681;

      :hover {
        opacity: 0.7;
      }
    }
    em {
      font-family: 'arial';
      font-weight: 400;
      font-size: 17px;
    }
`

const Shake = keyframes`
  30% {
    transform: scale(1) rotate(0);
  }
  32% {
    transform: scale(1.2) rotate(-10deg);
  }
  34% {
    transform: scale(1.2) rotate(10deg);
  }
  36% {
    transform: scale(1.2) rotate(-10deg);
  }
  38% {
    transform: scale(1.2) rotate(10deg);
  }
  40% {
    transform: scale(1.2) rotate(-10deg);
  }
  42% {
    transform: scale(1.2) rotate(10deg);
  }
  44% {
    transform: scale(1.2) rotate(-10deg);
  }
  46% {
    transform: scale(1.2) rotate(10deg);
  }

  47% {
    transform: scale(1) rotate(0);
  }

  56% {
    transform: scale(1.2) rotate(-10deg);
  }
  58% {
    transform: scale(1.2) rotate(10deg);
  }
  60% {
    transform: scale(1.2) rotate(-10deg);
  }
  62% {
    transform: scale(1.2) rotate(10deg);
  }
  64% {
    transform: scale(1.2) rotate(-10deg);
  }
  66% {
    transform: scale(1.2) rotate(10deg);
  }
  68% {
    transform: scale(1.2) rotate(-10deg);
  }
  70% {
    transform: scale(1) rotate(0);
  }
`

export const RootWrapper = styled.div`
  display: flex;
  align-items: center;
  background-image: url(${masterBack});
  background-position: center;
  background-repeat: repeat;
  background-size: 80%;
  min-height: 100vh;

  /****** For the side bar ******/
  aside {
    text-align: center;
    flex: 0 0 25%;

    #top {
      margin-bottom: 100px;

      #top-section {
        margin-bottom: 40px;

        ul {
          display: flex;
          justify-content: center;

          li {
            margin: 10px 25px;
          }
        }
      }
      h3 {
        width: 100%;
      }
      #tag-links-section {
        display: inline-block;

        .icon {
          font-size: 34px;
          order: -1;
          margin-right: 12px;

          img {
            width: 29px;
          }
        }
        li {
          display: flex;
          align-items: center;

          :first-child {
            justify-content: center;
          }
          :nth-child(2) .icon {
            color: #468df1;
          }
          :nth-child(4) .icon {
            color: #67bd47;
          }
          :nth-child(5) .icon {
            color: #435284;
          }
          :last-child .icon {
            color: #ead54c;
          }
        }

        .active-tag {
          color: red;
        }
      }
    }
    ul {
      padding: 0;
      font-family: "neucha", "open sans";
      list-style-type: none;

      li,
      a {
        color: black;
        cursor: pointer;
        font-size: 25px;
        margin-bottom: 10px;

        :hover {
          opacity: 0.7;
        }
      }
    }

    #bottom {
      #social-section {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;

        a {
          color: black;
          margin: 10px;
          font-size: 28px;

          :hover {
            color: gray;
          }
          :nth-child(2) svg {
            animation: 5s ${Shake} infinite ease-in-out;
          }
        }
      }
    }
  }

  /****** For the portfolio listing section ******/
  #main-grid {
    display: flex;
    flex-flow: row wrap;
    flex: 1 auto;

    .portfolio {
      cursor: pointer;
      width: 300px;
      flex: 0 1 32%;
      margin: 0 5px 20px 5px;

      :hover {
        opacity: 0.7;
      }
      .title-section {
        text-align: center;
        margin-top: 10px;
        padding: 7px 10px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 4px;
        color: white;

        p {
          font-family: "fjalla";
          margin: 0;
          font-size: 18px;
        }
      }
    }
  }

  /****** For the about page + Single portfolio ******/
  .content {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .col-1 {
      flex: 0 0 50%;

      h1 {
        margin-top: 0;
      }
      ul li {
        font-family: "neucha", "open sans";
        font-size: 22px;
        margin-bottom: 16px;
      }
    }
    .col-2 {
      flex: 1 auto;
      position: relative;
      margin: 0 30px;
    }
  }

  /****** For the footer ******/
  footer {
    display: none;
    text-align: center;
    padding: 10px;
    background-color: black;
    color: white;
  }
  .copyright-section {
    font-family: "neucha", "open sans";
    font-size: 18px;
  }

  @media (max-width: 1115px) {
    flex-direction: column;
    align-items: unset;

    aside {
      #top {
        margin-top: 40px;
        margin-bottom: 20px;

        #top-section {
          margin-bottom: 0px;
        }
        ul#tag-links-section {
          display: flex;
          justify-content: center;

          li.the-tag {
            border-right: 1px solid gray;
            padding: 0 10px;

            :last-child {
              border-right: none;
            }
            .icon {
              font-size: 24px;

              img {
                width: 21px;
              }
            }
          }
        }
        .blank-space {
          display: none;
        }
      }
      #bottom {
        margin-bottom: ${props => (props.home ? "65px" : "15px")};

        #social-section {
          margin: 0 auto;
          width: 550px;
          max-width: 100%;
          justify-content: space-evenly;
        }
        .copyright-section {
          display: none;
        }
      }
    }

    #main-grid {
      margin-bottom: 60px;
    }
    footer {
      display: block;
    }
  }

  @media (max-width: 760px) {
    #main-grid .portfolio {
      flex: 0 1 48%;
    }
  }
  @media (max-width: 710px) {
    aside #top {
      ul#tag-links-section {
        align-items: center;

        li.the-tag {
          border-right: none;
          padding: 0 15px;

          .text {
            display: none;
          }
          .icon {
            display: block;
            font-size: 32px;
            margin-right: 0px;

            img {
              width: 27px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 557px) {
    .content {
      .col-1 {
        h1 {
          margin-top: 60px;
          font-size: 30px;
        }
        a {
          font-size: 19px;
        }
        p {
          font-size: 18px;
        }
        ul li {
          font-size: 18px;
        }
      }
    }
  }
  @media (max-width: 499px) {
    aside #top ul#tag-links-section li.the-tag {
      padding: 0 7px;

      .icon {
        font-size: 27px;

        img {
          width: 22px;
        }
      }
    }
    #main-grid .portfolio {
      flex: unset;
      width: 100%;
      margin: 20px;
    }
  }
  @media (max-width: 340px) {
  }
`
