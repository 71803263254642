import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

const Seo = ({ title, description }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author
          siteUrl
          keywords
          image
        }
      }
    }
  `)
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        {data.site.siteMetadata.title} | {title}
      </title>
      <link rel="canonical" href={data.site.siteMetadata.siteUrl} />
      <meta name="author" content={data.site.siteMetadata.author} />
      <meta name="description" content={description} />
      <meta name="image" content={data.site.siteMetadata.image} />

      <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${data.site.siteMetadata.title} | ${title}`} />
      <meta name="description" property="og:description" content={description} />
      <meta name="image" property="og:image" content={data.site.siteMetadata.image} />

      <meta name="twitter:creator" content={data.site.siteMetadata.author} />
      <meta name="twitter:title" content={`${data.site.siteMetadata.title} | ${title}`} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={data.site.siteMetadata.image} />
    </Helmet>
  )
}

export default Seo
